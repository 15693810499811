import React from 'react';
import { useNavigate } from 'react-router-dom';
import { addToWaitlist } from '../firebase';
import '../styles/ContactForm.css';
import { countries } from '../data/countries';

const ContactForm: React.FC = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate('/');
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;
    const formData = {
      firstName: (form.querySelector('#firstName') as HTMLInputElement).value,
      lastName: (form.querySelector('#lastName') as HTMLInputElement).value,
      email: (form.querySelector('#email') as HTMLInputElement).value,
      country: (form.querySelector('#country') as HTMLSelectElement).value,
      profession: (form.querySelector('#profession') as HTMLSelectElement).value,
      linkedin: (form.querySelector('#linkedin') as HTMLInputElement).value,
      github: (form.querySelector('#github') as HTMLInputElement).value,
      portfolio: (form.querySelector('#portfolio') as HTMLInputElement).value,
      additional: (form.querySelector('#additional') as HTMLTextAreaElement).value,
    };

    console.log('Submitting form data:', formData);

    try {
      const result = await addToWaitlist(formData);
      console.log('Submission result:', result);
      if (result.success) {
        alert('Thank you for joining our waitlist!');
        navigate('/');
      } else {
        console.error('Submission failed:', result.error);
        alert('Something went wrong. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('An error occurred. Please try again later.');
    }
  };

  return (
    <div className="contact-page">
      <div className="split-container">
        <div className="info-section">
          <h1>Transform Your Freelance Career</h1>
          <p className="subtitle">
            Just like how Hollywood stars have agents to handle their business affairs, 
            we'll be your AI-powered agent to manage your freelancing career - from finding 
            opportunities to handling client relationships. Let us handle your business while 
            you focus on your craft.
          </p>
        </div>

        <div className="form-section">
          <div className="form-header">
            <h2>Join thousands of freelancers on our waitlist</h2>
            <p>Please tell us about yourself</p>
          </div>
          
          <form onSubmit={handleSubmit}>
            <div className="form-grid">
              <div className="form-field">
                <label htmlFor="firstName">First name</label>
                <input type="text" id="firstName" placeholder="Your first name" required />
              </div>
              
              <div className="form-field">
                <label htmlFor="lastName">Last name</label>
                <input type="text" id="lastName" placeholder="Your last name" required />
              </div>

              <div className="form-field full-width">
                <label htmlFor="email">Email</label>
                <input type="email" id="email" placeholder="you@example.com" required />
              </div>

              <div className="form-field full-width">
                <label htmlFor="country">Country of residence</label>
                <select id="country" required>
                  <option value="">Select your country</option>
                  {countries.map(country => (
                    <option key={country.code} value={country.code}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-field full-width">
                <label htmlFor="profession">Profession</label>
                <select id="profession" required>
                  <option value="">Select your profession</option>
                  <option value="developer">Software Developer</option>
                  <option value="designer">Designer</option>
                  <option value="writer">Content Writer</option>
                  <option value="marketing">Digital Marketing</option>
                  <option value="other">Other</option>
                </select>
              </div>

              <div className="form-field full-width">
                <label htmlFor="linkedin">LinkedIn profile</label>
                <input type="url" id="linkedin" placeholder="linkedin.com/in/your-profile" required />
              </div>

              <div className="form-field full-width">
                <label htmlFor="github">GitHub profile (optional)</label>
                <input type="url" id="github" placeholder="github.com/your-username" />
              </div>

              <div className="form-field full-width">
                <label htmlFor="portfolio">Portfolio URL (optional)</label>
                <input type="url" id="portfolio" placeholder="your-portfolio.com" />
              </div>

              <div className="form-field full-width">
                <label htmlFor="additional">Tell us more about your practice</label>
                <textarea
                  id="additional" 
                  placeholder="Share your freelance experience, goals, and what you're looking to achieve with StarAgent"
                  rows={4}
                ></textarea>
                <p className="field-note">Optional</p>
              </div>
            </div>

            <div className="form-actions">
              <button type="button" className="button-secondary" onClick={handleBack}>Back</button>
              <button type="submit" className="button-primary">Continue</button>
            </div>

            <p className="privacy-notice">
              StarAgent will handle your data pursuant to our <a href="/privacy">Privacy Policy</a>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactForm; 