import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Footer.css';

const Footer: React.FC = () => {
  return (
    <footer>
      <div className="container">
        <div className="footer-content">
          <div className="logo">StarAgent</div>
          <div className="footer-links">
            <Link to="/privacy">Privacy Policy</Link>
            <Link to="/terms">Terms of Service</Link>
            <a href="mailto:hello@staragent.ai">Contact</a>
          </div>
        </div>
        <p className="copyright">© 2024 StarAgent. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer; 