import React from 'react';
import '../styles/Features.css';

const Features: React.FC = () => {
  const features = [
    {
      icon: '💼',
      title: 'Income Stability',
      description: 'Smart pipeline planning and project hunting to maintain steady income while advancing your career.'
    },
    {
      icon: '📈',
      title: 'Dynamic Pricing',
      description: 'Optimize your rates based on market demand, workload, and client reliability scores.'
    },
    {
      icon: '🤝',
      title: 'Client Management',
      description: 'Handle scope creep, payment reminders, and negotiations professionally and automatically.'
    }
  ];

  return (
    <section className="features">
      <div className="container">
        <div className="content-wrapper">
          <h2>What Your AI Agent Does</h2>
        </div>
        <div className="feature-grid">
          {features.map((feature, index) => (
            <div key={index} className="feature-card">
              <div className="feature-icon">{feature.icon}</div>
              <h3>{feature.title}</h3>
              <p>{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features; 