import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Hero.css';

const Hero: React.FC = () => {
  return (
    <header className="hero">
      <div className="container">
        <div className="content-wrapper">
          <h1>Hollywood Stars' Agent, for Freelancers</h1>
          <p className="subtitle">Stop managing your business. Start focusing on your craft.</p>
          <Link to="/join-waitlist" className="cta-button">Get Early Access</Link>
        </div>
      </div>
    </header>
  );
};

export default Hero; 