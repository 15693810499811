import React, { useEffect, ReactNode } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { logAnalyticsEvent } from './firebase';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Features from './components/Features';
import HowItWorks from './components/HowItWorks';
import CTA from './components/CTA';
import Footer from './components/Footer';
import TermsOfService from './components/TermsOfService';
import PrivacyPolicy from './components/PrivacyPolicy';
import ContactForm from './components/ContactForm';
import './styles/App.css';

// Create interface for the wrapper props
interface AnalyticsWrapperProps {
  children: ReactNode;
}

// Create a component to handle analytics
function AnalyticsWrapper({ children }: AnalyticsWrapperProps) {
  const location = useLocation();

  useEffect(() => {
    logAnalyticsEvent('page_view', {
      page_path: location.pathname,
      page_title: document.title
    });
  }, [location]);

  return <>{children}</>;
}

function App() {
  return (
    <Router>
      <AnalyticsWrapper>
        <div className="App">
          <Routes>
            <Route path="/" element={
              <>
                <Navbar />
                <Hero />
                <Features />
                <HowItWorks />
                <CTA />
                <Footer />
              </>
            } />
            <Route path="/terms" element={
              <>
                <Navbar />
                <TermsOfService />
                <Footer />
              </>
            } />
            <Route path="/privacy" element={
              <>
                <Navbar />
                <PrivacyPolicy />
                <Footer />
              </>
            } />
            <Route path="/join-waitlist" element={<ContactForm />} />
          </Routes>
        </div>
      </AnalyticsWrapper>
    </Router>
  );
}

export default App; 