import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/CTA.css';

const CTA: React.FC = () => {
  return (
    <section className="cta">
      <div className="container">
        <div className="content-wrapper">
          <h2>Ready to Transform Your Freelance Career?</h2>
          <p>Join thousands of freelancers already on our waitlist</p>
          <Link to="/join-waitlist" className="cta-button">Join Waitlist</Link>
        </div>
      </div>
    </section>
  );
};

export default CTA; 