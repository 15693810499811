import React from 'react';
import '../styles/HowItWorks.css';

const HowItWorks: React.FC = () => {
  const steps = [
    {
      number: 1,
      title: 'Install Extension',
      description: 'Add StarAgent to Chrome in seconds'
    },
    {
      number: 2,
      title: 'Connect Platforms',
      description: 'Works with any freelance marketplace'
    },
    {
      number: 3,
      title: 'Let AI Handle Business',
      description: 'Focus on what you do best'
    }
  ];

  return (
    <section className="how-it-works">
      <div className="container">
        <h2>Seamless Integration</h2>
        <div className="steps">
          {steps.map((step, index) => (
            <div key={index} className="step">
              <div className="step-number">{step.number}</div>
              <h3>{step.title}</h3>
              <p>{step.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HowItWorks; 