import React from 'react';
import '../styles/Legal.css';

const TermsOfService: React.FC = () => {
  return (
    <div className="legal-page">
      <div className="container">
        <div className="content-wrapper">
          <h1>Terms of Service</h1>
          <p className="last-updated">Last updated: November 26, 2024</p>

          <section>
            <h2>1. Introduction</h2>
            <p>Welcome to StarAgent. By using our services, you're agreeing to these terms. Please read them carefully.</p>
          </section>

          <section>
            <h2>2. Using our Services</h2>
            <p>You must follow any policies made available to you within the Services. You may use our Services only as permitted by law. We may suspend or stop providing our Services to you if you do not comply with our terms or policies or if we are investigating suspected misconduct.</p>
          </section>

          <section>
            <h2>3. Your StarAgent Account</h2>
            <p>You need a StarAgent Account to use our services. You are responsible for maintaining the security of your account and the activities that occur under your account.</p>
          </section>

          <section>
            <h2>4. Privacy and Copyright Protection</h2>
            <p>StarAgent's privacy policies explain how we treat your personal data and protect your privacy when you use our Services. By using our Services, you agree that StarAgent can use such data in accordance with our privacy policies.</p>
          </section>

          <section>
            <h2>5. Your Content in our Services</h2>
            <p>Our Services allow you to upload, submit, store, send or receive content. You retain ownership of any intellectual property rights that you hold in that content.</p>
          </section>

          <section>
            <h2>6. Service Modifications</h2>
            <p>We are constantly changing and improving our Services. We may add or remove functionalities or features, and we may suspend or stop a Service altogether.</p>
          </section>

          <section>
            <h2>7. Warranties and Disclaimers</h2>
            <p>We provide our Services using reasonable skill and care. Other than as expressly set out in these terms or additional terms, neither StarAgent nor its suppliers or distributors make any specific promises about the Services.</p>
          </section>

          <section>
            <h2>8. Liability for our Services</h2>
            <p>When permitted by law, StarAgent, and StarAgent's suppliers and distributors, will not be responsible for lost profits, revenues, or data, financial losses or indirect, special, consequential, exemplary, or punitive damages.</p>
          </section>

          <section>
            <h2>9. Business uses of our Services</h2>
            <p>If you are using our Services on behalf of a business, that business accepts these terms. It will hold harmless and indemnify StarAgent and its affiliates, officers, agents, and employees from any claim, suit or action arising from or related to the use of the Services.</p>
          </section>

          <section>
            <h2>10. About these Terms</h2>
            <p>We may modify these terms or any additional terms that apply to a Service to, for example, reflect changes to the law or changes to our Services. You should look at the terms regularly.</p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService; 