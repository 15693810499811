import React from 'react';
import '../styles/Legal.css';

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="legal-page">
      <div className="container">
        <div className="content-wrapper">
          <h1>Privacy Policy</h1>
          <p className="last-updated">Last updated: November 26, 2024</p>

          <section>
            <h2>1. Introduction</h2>
            <p>At StarAgent, we take your privacy seriously. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our service.</p>
          </section>

          <section>
            <h2>2. Information We Collect</h2>
            <h3>2.1 Information you provide to us:</h3>
            <ul>
              <li>Account information (name, email, professional details)</li>
              <li>Profile information</li>
              <li>Communication preferences</li>
              <li>Payment information</li>
            </ul>

            <h3>2.2 Information automatically collected:</h3>
            <ul>
              <li>Usage information</li>
              <li>Device information</li>
              <li>Log data</li>
              <li>Cookies and similar technologies</li>
            </ul>
          </section>

          <section>
            <h2>3. How We Use Your Information</h2>
            <p>We use the information we collect to:</p>
            <ul>
              <li>Provide and maintain our Service</li>
              <li>Improve and personalize your experience</li>
              <li>Communicate with you</li>
              <li>Ensure security and prevent fraud</li>
              <li>Comply with legal obligations</li>
            </ul>
          </section>

          <section>
            <h2>4. Information Sharing and Disclosure</h2>
            <p>We may share your information with:</p>
            <ul>
              <li>Service providers and business partners</li>
              <li>Legal authorities when required by law</li>
              <li>Other users as part of the normal operation of the service</li>
            </ul>
          </section>

          <section>
            <h2>5. Data Security</h2>
            <p>We implement appropriate technical and organizational measures to protect your personal information against unauthorized or unlawful processing, accidental loss, destruction, or damage.</p>
          </section>

          <section>
            <h2>6. Your Rights and Choices</h2>
            <p>You have the right to:</p>
            <ul>
              <li>Access your personal information</li>
              <li>Correct inaccurate data</li>
              <li>Request deletion of your data</li>
              <li>Object to processing of your data</li>
              <li>Request data portability</li>
            </ul>
          </section>

          <section>
            <h2>7. International Data Transfers</h2>
            <p>Your information may be transferred to and processed in countries other than your country of residence. We ensure appropriate safeguards are in place for such transfers.</p>
          </section>

          <section>
            <h2>8. Changes to This Privacy Policy</h2>
            <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date.</p>
          </section>

          <section>
            <h2>9. Contact Us</h2>
            <p>If you have any questions about this Privacy Policy, please contact us at privacy@staragent.ai</p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy; 